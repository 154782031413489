import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Course } from '../quests/Quests';
import './CourseCard.css';

interface StudentPreview {
  fullName: string;
}

interface CoursePreviewProps extends Course {
  studentsPreview?: StudentPreview[];
  waitingCount?: number; // Waiting count for the course
}

const CourseCard: React.FC<CoursePreviewProps> = ({
  id,
  name,
  accessCodeBlocked,
  accessCode,
  studentsPreview = [],
  waitingCount = 0, // Waiting count for the course
}) => {
  const { t } = useTranslation('global');

  const maxToShow = 3;
  const displayedStudents = studentsPreview.slice(0, maxToShow);
  const extraCount = studentsPreview.length - maxToShow;

  const getInitials = (fullName: string): string => {
    const parts = fullName.trim().split(/\s+/);
    if (parts.length === 1) {
      return parts[0].charAt(0).toUpperCase();
    }
    const firstInitial = parts[0].charAt(0).toUpperCase();
    const lastInitial = parts[parts.length - 1].charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(accessCode);
  };

  return (
    <div className='card course-card'>
      {/* Optional: Render waiting count badge */}
      {waitingCount > 0 && <div className='waiting-badge'>{waitingCount}</div>}
      <Link to={`/courses/${id}`} className='link-style'>
        <div className='course-card-preview'>
          <div className='course-preview-header'>
            <h5 className='course-name'>{name}</h5>
          </div>
          <div className='course-preview-content'>
            <h6 className='students-subtitle'>{t('students')}</h6>
            <div className='students-preview'>
              {displayedStudents.length > 0 ? (
                displayedStudents.map((student, index) => {
                  const initials = getInitials(student.fullName);

                  return (
                    <div key={index} className='student-preview-item'>
                      <div
                        className='student-initials'
                        title={student.fullName}
                        style={{ animationDelay: `${index * 0.2}s` }}
                      >
                        {initials}
                      </div>
                      <span className='student-name'>{student.fullName}</span>
                    </div>
                  );
                })
              ) : (
                <div className='no-students'>{t('noStudentsPreview')}</div>
              )}
              {extraCount > 0 && (
                <div className='student-preview-item extra-item'>+{extraCount}</div>
              )}
            </div>
          </div>
        </div>
        <div className='course-card-footer'>
          {accessCodeBlocked ? (
            <span className='badge bg-danger' data-testid='blocked'>
              {t('courseBlocked')}
            </span>
          ) : (
            <div className='access-code-container'>
              <p className='access-code' data-testid='access'>
                {t('accessCode')}: <span className='fw-semibold'>{accessCode}</span>
              </p>
            </div>
          )}
        </div>
      </Link>
      {!accessCodeBlocked && (
        <button className='copy-button' onClick={copyToClipboard} title='Copy to clipboard'>
          📋
        </button>
      )}
    </div>
  );
};

export default CourseCard;
