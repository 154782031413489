import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading'; // Corrected relative path
import './AnnouncementsModal.css'; // Custom styles for the modal

interface AnnouncementsModalProps {
  isOpen: boolean;
  isClosing?: boolean;
  // Recipients: an array of participant IDs.
  // If sending to all students, pass an array of all student IDs.
  recipients: number[];
  onClose: () => void;
  // onSend should return a Promise so we can display a loader on failure.
  onSend: (message: string, recipients: number[]) => Promise<void>;
}

const AnnouncementsModal: React.FC<AnnouncementsModalProps> = ({
  isOpen,
  isClosing,
  recipients,
  onClose,
  onSend,
}) => {
  const { t } = useTranslation('global');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');
  const [confirmation, setConfirmation] = useState('');

  if (!isOpen && !isClosing) return null;

  const handleSend = async () => {
    if (message.trim() === '') {
      setError(t('messageEmptyError') || 'Message cannot be empty.');
      return;
    }
    setError('');
    setSending(true);
    try {
      await onSend(message, recipients);
      // Set confirmation message using translation keys.
      const confirmationMsg =
        recipients.length === 1
          ? t('messageSentToStudentConfirmation') || 'Message sent to student successfully!'
          : t('announcementSentToAllConfirmation') ||
            'Announcement sent to all students successfully!';
      setConfirmation(confirmationMsg);
      setMessage('');
      // Keep the confirmation message visible for 3 seconds before closing the modal.
      setTimeout(() => {
        setConfirmation('');
        onClose();
      }, 3000);
    } catch (err) {
      setError(t('sendFailed') || 'Failed to send message.');
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      {/* Modal backdrop */}
      <div className='modal-backdrop show' style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}></div>
      <div
        className={`modal fade announcements-modal ${isOpen ? 'show' : ''} ${
          isClosing ? 'modal-drop-out' : 'modal-drop-in'
        }`}
        tabIndex={-1}
        style={{ display: isOpen || isClosing ? 'block' : 'none' }}
        role='dialog'
      >
        <div className='modal-dialog modal-md modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {recipients.length === 1 ? t('sendMessageToStudent') : t('sendAnnouncementToAll')}
              </h5>
              <button
                type='button'
                className='btn btn-close'
                onClick={onClose}
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              {error && <div className='alert alert-danger'>{error}</div>}
              {confirmation ? (
                <div className='alert alert-success'>{confirmation}</div>
              ) : (
                <div className='mb-3'>
                  <label htmlFor='announcementMessage' className='form-label'>
                    {t('message')}
                  </label>
                  <textarea
                    id='announcementMessage'
                    className='form-control'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                  ></textarea>
                </div>
              )}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' onClick={onClose}>
                {t('cancel')}
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleSend}
                disabled={sending || !!confirmation}
              >
                {sending ? <Loading insideModal='announcements' /> : t('send')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementsModal;
