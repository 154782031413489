import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../utils/stores';
import { useQuery } from 'react-query';
import { fetchAPI } from '../../utils/httpRequests';
import Loading from '../../components/Loading';
import CourseCard from '../teacher/cards/CourseCard';
import AvatarCard from '../student/cards/AvatarCard';
import AnnouncementsInbox from '../../pages/signed-in/AnnouncementsInbox';
import './Welcome.css';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

interface Avatar {
  avatar: string;
  avatarName: string | null;
  code: string;
  id: number;
  role: string;
  course: {
    id: number;
    name: string;
    xpPerLevel: number;
    teacher?: {
      firstName: string;
      lastName: string;
    };
  };
  xp: number;
  gold: number;
  life: number;
}

interface Participant {
  id: number;
  avatar: string;
  user: {
    firstName: string;
    lastName: string;
    email?: string;
  };
  role: string;
}

interface CourseWithParticipants {
  id: number;
  name: string;
  accessCodeBlocked: boolean;
  accessCode: string;
  participants?: Participant[];
}

interface WaitingCount {
  courseId: number;
  waitingCount: number;
}

const Welcome = () => {
  const { t } = useTranslation('global');
  // Now we also read isProfileMenuOpen from your store
  const { user, signedIn, isProfileMenuOpen } = useAuth();

  const [inboxOpen, setInboxOpen] = useState(true);

  const fetchClasses = async (): Promise<CourseWithParticipants[] | null> => {
    const classes = await fetchAPI<CourseWithParticipants[]>('/courses');
    return classes.isSuccess ? classes.data : null;
  };

  const fetchAvatars = async (): Promise<Avatar[] | null> => {
    const avatars = await fetchAPI<Avatar[]>('/participants');
    return avatars.isSuccess ? avatars.data : null;
  };

  //For teacher course notification
  const fetchWaitingCounts = async (): Promise<WaitingCount[] | null> => {
    const response = await fetchAPI<WaitingCount[]>(`/classes/waitingCounts?ts=${Date.now()}`);
    return response.isSuccess ? (response.data ?? null) : null;
  };
  const { data: waitingCounts = [], isLoading: waitingCountsLoading = false } =
    useQuery('waitingCounts', fetchWaitingCounts) || {};

  const { data: classes, isLoading: classesLoading } = useQuery('courses', fetchClasses) || {};
  const { data: avatars = [], isLoading: avatarsLoading = false } =
    useQuery('participants', fetchAvatars) || {};

  if (classesLoading || avatarsLoading || waitingCountsLoading) {
    return (
      <div className='p-5'>
        <Loading />
      </div>
    );
  }

  const teacherFirstName = user?.firstName || 'Teacher';
  const teacherLastName = user?.lastName || '';
  const studentFirstName = user?.firstName || 'Student';
  const studentLastName = user?.lastName || '';

  // Create a map for quick lookup of waiting counts by course ID.
  const waitingCountsMap =
    waitingCounts?.reduce(
      (acc, item) => {
        acc[item.courseId] = item.waitingCount;
        return acc;
      },
      {} as Record<number, number>,
    ) || {};

  return (
    <div className='welcome-container' data-testid='welcome'>
      {signedIn && user?.teacher ? (
        // -------------------- TEACHER VIEW --------------------
        <div className='container p-4'>
          <div className='welcome-message'>
            <h2>
              {t('welcomeTeacher', {
                firstName: teacherFirstName,
                lastName: teacherLastName,
              })}
            </h2>
            <p>{t('yourClassesLabel')}</p>
          </div>
          {classes && classes.length > 0 ? (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4'>
              {classes.map((course) => {
                const students = (course.participants || []).filter((p) => p.role === 'STUDENT');
                const studentsPreview = students.map((participant) => ({
                  avatar: participant.avatar || 'default-avatar.png',
                  fullName: `${participant.user.firstName || 'Unknown'} ${
                    participant.user.lastName || ''
                  }`.trim(),
                }));

                const waitingCount = waitingCountsMap[course.id] || 0;

                return (
                  <div className='col d-flex' key={course.id}>
                    <CourseCard
                      id={course.id}
                      name={course.name}
                      accessCodeBlocked={course.accessCodeBlocked}
                      accessCode={course.accessCode}
                      studentsPreview={studentsPreview}
                      waitingCount={waitingCount}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='mt-3'>
              <h5>{t('noClassesMessage')}</h5>
            </div>
          )}
        </div>
      ) : (
        // -------------------- STUDENT VIEW --------------------
        <div className='container p-4'>
          <div className='welcome-message'>
            <h2>
              {t('welcomeStudent', {
                firstName: studentFirstName,
                lastName: studentLastName,
              })}
            </h2>
            <p>{t('yourClassesLabel')}</p>
          </div>
          {avatars && avatars.length > 0 ? (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-4'>
              {avatars.map((avatar) => {
                const courseInfo = classes?.find((c) => c.id === avatar.course.id);
                const teacher = courseInfo?.participants?.find((p) => p.role === 'TEACHER');

                return (
                  <AvatarCard
                    key={avatar.id}
                    avatar={avatar.avatar}
                    avatarName={avatar.avatarName}
                    course={{
                      ...avatar.course,
                      teacher: teacher
                        ? {
                            firstName: teacher.user.firstName,
                            lastName: teacher.user.lastName,
                          }
                        : undefined,
                    }}
                    xp={avatar.xp}
                    gold={avatar.gold}
                  />
                );
              })}
            </div>
          ) : (
            <div className='container mt-3'>
              <h5>{t('noAvatarsMessage')}</h5>
            </div>
          )}

          {/* Show Inbox button 
              Only render if the inbox is hidden AND the user menu is NOT open 
          */}
          {!inboxOpen && !isProfileMenuOpen && (
            <button
              className='show-inbox-button'
              onClick={() => setInboxOpen(true)}
              aria-label={t('showInbox')}
              title={t('showInbox') || ''}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </button>
          )}

          {/* The Announcements Inbox */}
          <AnnouncementsInbox isHidden={!inboxOpen} onToggle={() => setInboxOpen(!inboxOpen)} />
        </div>
      )}
    </div>
  );
};

export default Welcome;
