import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CreateClassModal from './modals/CreateClassModal';
import { useAuth } from '../utils/stores';
import { fetchAPI } from '../utils/httpRequests';
import JoinClassModal from './modals/JoinClassModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { removePreviousTokens } from '../utils/auth';
import './Navbar.css';

interface JoinCourseResponse {
  courseId: string;
  participantId: string;
}

interface JoinCourse {
  accessCode: string;
}

const Navbar = () => {
  const { i18n, t } = useTranslation('global');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [joinedClassStatus, setJoinedClassStatus] = useState(404);
  const [courseId, setCourseId] = useState<number | null>(null);
  const [participantId, setParticipantId] = useState<number | null>(null);

  const { user, signedIn, setIsProfileMenuOpen } = useAuth();

  // React Hook Form for joining a class
  const { handleSubmit, register, watch, reset } = useForm<JoinCourse>();
  const accessCode = watch('accessCode');

  // Language Dropdown
  const LanguageDropdown = () => (
    <select
      className='language-dropdown'
      value={i18n.language}
      onChange={(e) => {
        const newLang = e.target.value;
        i18n.changeLanguage(newLang);
        localStorage.setItem('language', newLang);
      }}
    >
      <option value='en'>EN</option>
      <option value='fr'>FR</option>
      <option value='es'>ES</option>
    </select>
  );

  // -- Modals
  const openModal = () => setCreateModalOpen(true);
  const closeCreateModal = async () => setCreateModalOpen(false);
  const closeJoinModal = async () => setJoinModalOpen(false);

  // Join course logic
  const onSubmit = async (data: JoinCourse) => {
    await joinCourse(data);
  };

  const joinCourse = async (body: JoinCourse) => {
    const response = await fetchAPI(`/courses/join`, {
      method: 'POST',
      body,
    });
    setJoinedClassStatus(response.statusCode);

    if (response.isSuccess) {
      const data = response.data as JoinCourseResponse;
      setCourseId(Number(data.courseId));
      setParticipantId(Number(data.participantId));
    } else {
      setCourseId(null);
      setParticipantId(null);
    }
    setJoinModalOpen(true);
  };

  // Reset access code when tokens are removed
  useEffect(() => {
    const handleTokensRemoved = () => reset({ accessCode: '' });
    window.addEventListener('tokensRemoved', handleTokensRemoved);
    return () => {
      window.removeEventListener('tokensRemoved', handleTokensRemoved);
    };
  }, [reset]);

  useEffect(() => {
    const handleShown = (e: Event) => {
      const target = e.target as HTMLElement;
      // Check if the shown dropdown is the user menu
      if (target.closest('.user-menu')) {
        console.log('User dropdown shown');
        setIsProfileMenuOpen(true);
      }
    };

    const handleHidden = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.closest('.user-menu')) {
        console.log('User dropdown hidden');
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener('shown.bs.dropdown', handleShown);
    document.addEventListener('hidden.bs.dropdown', handleHidden);

    return () => {
      document.removeEventListener('shown.bs.dropdown', handleShown);
      document.removeEventListener('hidden.bs.dropdown', handleHidden);
    };
  }, [setIsProfileMenuOpen]);

  return (
    <>
      <nav className='navbar navbar-expand-md navbar-dark bg-dark' data-testid='navbar'>
        <div className='container-fluid px-3'>
          {!signedIn && (
            <Link className='navbar-brand' to='/'>
              {t('iq')}
            </Link>
          )}
          {signedIn && (
            <Link className='navbar-brand' to='/welcome'>
              {t('iq')}
            </Link>
          )}

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarCollapse'>
            {/* TEACHER NAV ITEMS */}
            {signedIn && user?.teacher && (
              <ul className='navbar-nav'>
                <li className='nav-item'>
                  <Link className='nav-link' to='/welcome'>
                    {t('classes')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/quests'>
                    {t('quests')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/stories'>
                    {t('stories')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/concepts'>
                    {t('concepts')}
                  </Link>
                </li>
              </ul>
            )}

            {/* STUDENT JOIN FORM */}
            {signedIn && user?.role === 'STUDENT' && (
              <form role='search' onSubmit={handleSubmit(onSubmit)} className='d-flex ms-auto'>
                <input
                  className='form-control me-2'
                  type='search'
                  placeholder={t('classCode')}
                  {...register('accessCode')}
                  aria-label='Search'
                />
                <button
                  className='btn btn-success my-2 my-sm-0'
                  type='submit'
                  style={{ whiteSpace: 'nowrap' }}
                  disabled={!accessCode}
                >
                  {t('join')}
                </button>
              </form>
            )}

            {/* RIGHT SIDE FOR SIGNED-IN USERS */}
            {signedIn && (
              <ul className='navbar-nav ms-auto align-items-center'>
                {/* Teacher-only button */}
                {user?.teacher && (
                  <li className='nav-item me-2'>
                    <button
                      className='btn btn-success my-2 my-sm-0'
                      onClick={openModal}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {t('createCourse')}
                    </button>
                  </li>
                )}

                {/* Single Language Dropdown */}
                <li className='nav-item me-2'>
                  <LanguageDropdown />
                </li>

                {/* User dropdown with a 'user-menu' class on the li */}
                <li className='nav-item dropdown user-menu'>
                  <a
                    className='nav-link dropdown-toggle'
                    href='#'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <FontAwesomeIcon className='icon' icon={faUser} />
                  </a>
                  <ul className='dropdown-menu dropdown-menu-end'>
                    <li>
                      <Link
                        className='dropdown-item'
                        onClick={removePreviousTokens}
                        to='/auth/sign-in'
                      >
                        {t('logout')}
                      </Link>
                    </li>
                    <li>
                      <Link className='dropdown-item' to='/settings'>
                        {t('settings')}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            )}

            {/* SIGNED-OUT NAV ITEMS */}
            {!signedIn && (
              <ul className='navbar-nav ms-auto align-items-center'>
                <li className='nav-item me-2'>
                  <LanguageDropdown />
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/auth/sign-up'>
                    {t('createAccount')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/auth/sign-in'>
                    {t('login')}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>

      {/* JoinClassModal */}
      <JoinClassModal
        isOpen={joinModalOpen}
        statusCode={joinedClassStatus}
        courseId={courseId ?? undefined}
        participantId={participantId ?? undefined}
        onClose={closeJoinModal}
      />

      {/* CreateClassModal */}
      <CreateClassModal isOpen={createModalOpen} onClose={closeCreateModal} />
    </>
  );
};

export default Navbar;
