import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchAPI, fetchQuest, UpdatedResponse } from '../../../utils/httpRequests';
import Loading from '../../../components/Loading';
import RenderUnsafeHTML from '../../../components/RenderUnsafeHTML';
import { ImagePinContainer } from 'react-image-pin';
import {
  ImagePin,
  ImagePinContainerRef,
  NewPinEvent,
} from 'react-image-pin/dist/components/ImagePinContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faFlag, faLocationDot, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';

import QuestStepModal from './modals/QuestStepModal';
import QuestSidebar from './QuestSidebar';
import defaultMap from './default-map.png';

import { QuestStep, Story } from './Quests';

export const startEndPins = [
  {
    positionX: 2,
    positionY: 4,
    stepOrder: -1,
    id: 'start',
    draggable: false,
  },
  {
    positionX: 85,
    positionY: 16,
    stepOrder: 99999999999999,
    id: 'end',
    draggable: false,
  },
];

export const getPinIcon = (id: string, defaultIcon: IconDefinition = faXmark) => {
  switch (id) {
    case 'start':
      return faLocationDot;
    case 'end':
      return faFlag;
    default:
      return defaultIcon;
  }
};

export const getPinBackgroundColor = (id: string, defaultColor: string = 'bg-danger') => {
  switch (id) {
    case 'start':
      return 'bg-warning';
    case 'end':
      return 'bg-info';
    default:
      return defaultColor;
  }
};

const PIN_MARGIN = 3;

const clamp = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, value));
};

const QuestPage = () => {
  const { t } = useTranslation('global');
  const { questId } = useParams<{ questId: string }>();

  const ref = useRef<ImagePinContainerRef>(null);

  const [imageLoaded, setImageLoaded] = useState(false);

  // State for QuestStep modal
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editQuestStep, setEditQuestStep] = useState<QuestStep | null>(null);
  const [questStepPositionX, setQuestStepPositionX] = useState<number | null>(null);
  const [questStepPositionY, setQuestStepPositionY] = useState<number | null>(null);
  const [relatedStory, setRelatedStory] = useState<Story | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const OVERLAP_THRESHOLD = 3;

  const {
    data: quest,
    isLoading,
    refetch,
  } = useQuery(`quest-${questId}`, () => fetchQuest(questId));

  useEffect(() => {
    const img = new Image();
    img.src = defaultMap;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  useEffect(() => {
    if (quest && imageLoaded && ref.current) {
      const delays = [100, 500, 1000];
      const timeouts = delays.map((delay) =>
        setTimeout(() => {
          ref.current?.rerender();
        }, delay),
      );
      return () => {
        timeouts.forEach(clearTimeout);
      };
    }
  }, [quest, imageLoaded]);

  const getAllPins = () => {
    if (!quest) return [];
    return [...quest.questSteps, ...startEndPins];
  };

  const isOverlappingWithExistingPin = (x: number, y: number, pinId?: string) => {
    const allPins = getAllPins();
    return allPins.some((step) => {
      if (pinId && String(step.id) === pinId) return false;
      const dx = step.positionX - x;
      const dy = step.positionY - y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      return distance < OVERLAP_THRESHOLD;
    });
  };

  const showOverlapErrorWhenCreating = () => {
    setErrorMessage(t('pinOverlapErrorWhenCreating'));
  };

  const showOverlapErrorWhenDragging = () => {
    setErrorMessage(t('pinOverlapErrorWhenDragging'));
  };

  // Called when user clicks an existing pin
  const existingPinClick = (pin: ImagePin): void => {
    if (!quest) return;
    setErrorMessage(null);
    const qs = quest.questSteps.find((s) => String(s.id) === pin.id);
    if (!qs) return;
    setQuestStepPositionX(pin.positionX);
    setQuestStepPositionY(pin.positionY);
    setEditQuestStep(qs);
    setRelatedStory(
      quest.masterStory.stories.at(quest.questSteps.findIndex((s) => s.id === qs.id)) || null,
    );
    setCreateModalOpen(true);
  };

  // Called when user clicks on the map to create a pin
  const handleNewPin = (newPinEvent: NewPinEvent): void => {
    if (!quest) return;
    setErrorMessage(null);

    // Clamp so the entire pin stays on the map
    const clampedX = clamp(newPinEvent.positionX, PIN_MARGIN, 100 - PIN_MARGIN);
    const clampedY = clamp(newPinEvent.positionY, PIN_MARGIN, 100 - PIN_MARGIN);

    if (isOverlappingWithExistingPin(clampedX, clampedY)) {
      showOverlapErrorWhenCreating();
      return;
    }

    setQuestStepPositionX(clampedX);
    setQuestStepPositionY(clampedY);
    setRelatedStory(quest.masterStory.stories.at(quest.questSteps.length) || null);
    setCreateModalOpen(true);
  };

  // Called when user drags an existing pin
  const handleDraggedPin = async (pin: ImagePin): Promise<void> => {
    if (!quest) return;
    const questStep = quest.questSteps.find((qs) => String(qs.id) === pin.id);
    if (!questStep) return;

    // Clamp so the entire pin stays on the map
    const clampedX = clamp(pin.positionX, PIN_MARGIN, 100 - PIN_MARGIN);
    const clampedY = clamp(pin.positionY, PIN_MARGIN, 100 - PIN_MARGIN);

    if (isOverlappingWithExistingPin(clampedX, clampedY, pin.id)) {
      showOverlapErrorWhenDragging();
      await refetch();
      ref.current?.rerender();
      return;
    }

    // Save the new position
    await fetchAPI<UpdatedResponse>(`/quests/steps/${questStep.id}`, {
      method: 'PUT',
      body: {
        ...questStep,
        positionX: clampedX,
        positionY: clampedY,
        conceptId: questStep.concept.id,
      },
    });

    // Update local state, then refresh and rerender
    questStep.positionX = clampedX;
    questStep.positionY = clampedY;
    await refetch();
    ref.current?.rerender();
  };

  const closeModal = async (): Promise<void> => {
    setCreateModalOpen(false);
    setEditQuestStep(null);
    setQuestStepPositionX(null);
    setQuestStepPositionY(null);
    await refetch();
    ref.current?.rerender();
  };

  if (isLoading || !imageLoaded) {
    return <Loading />;
  }

  return (
    <div className='d-flex' data-testid='questPage'>
      {/* Sidebar */}
      <QuestSidebar />

      {/* Main Content */}
      <div className='flex-grow-1 mx-3 mx-lg-5 my-2'>
        {errorMessage && (
          <div className='alert alert-danger alert-dismissible fade show' role='alert'>
            <FontAwesomeIcon className='me-2' icon={faCircleInfo} />
            {errorMessage}
            <button
              type='button'
              className='btn-close'
              aria-label='Close'
              onClick={() => setErrorMessage(null)}
            />
          </div>
        )}

        {!quest ? (
          <div>{t('questNotFound')}</div>
        ) : (
          <>
            <h1 className='mb-2'>
              {t('quest')} - {quest.name}
            </h1>
            <div className='alert alert-info'>
              <FontAwesomeIcon className='me-1' icon={faCircleInfo} />
              {t('clickAnywhereOnTheMapToCreateAQuestStep')}
            </div>
            <div>
              <RenderUnsafeHTML className='fs-5' html={quest.introduction} />
            </div>
            <div>
              <ImagePinContainer
                ref={ref}
                image={defaultMap}
                pins={[...startEndPins, ...(quest.questSteps ?? [])]
                  .sort((a, b) => a.stepOrder - b.stepOrder)
                  .map((s) => ({
                    positionX: s.positionX,
                    positionY: s.positionY,
                    id: String(s.id),
                    draggable: s.id !== 'start' && s.id !== 'end',
                  }))}
                customPinComponent={(pin) => (
                  <button
                    className={`rounded-circle shadow-sm ${getPinBackgroundColor(pin.id)}`}
                    style={{ width: '3em', height: '3em' }}
                  >
                    <FontAwesomeIcon icon={getPinIcon(pin.id)} size='2x' className='text-white' />
                  </button>
                )}
                arrow={{ color: '#000' }}
                onNewPin={handleNewPin}
                onExistingPin={existingPinClick}
                onDraggedPin={handleDraggedPin}
                draggable={true}
              />
            </div>
          </>
        )}
      </div>

      {!!questStepPositionX && !!questStepPositionY && (
        <QuestStepModal
          isOpen={createModalOpen}
          onClose={closeModal}
          questId={Number(questId)}
          relatedStory={relatedStory}
          editQuestStep={editQuestStep}
          positionX={questStepPositionX}
          positionY={questStepPositionY}
        />
      )}
    </div>
  );
};

export default QuestPage;
