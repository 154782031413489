import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { fetchAPI } from '../../utils/httpRequests';
import { useAuth } from '../../utils/stores';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import './AnnouncementsInbox.css';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

export interface Announcement {
  id: number;
  courseName: string; // friendly course name
  message: string;
  dateSent: string;
  isRead: boolean;
  teacherName: string;
}

interface AnnouncementsInboxProps {
  isHidden: boolean;
  onToggle: () => void;
}

/**
 * Fetch announcements for a given recipient (userId).
 */
const fetchAnnouncements = async (userId: number): Promise<Announcement[]> => {
  const response = await fetchAPI<Announcement[]>(`/announcements/inbox?recipientId=${userId}`);
  if (response.isSuccess) return response.data;
  throw new Error('Error fetching announcements');
};

/**
 * Delete an announcement for a specific user.
 */
const deleteAnnouncementForUser = async ({
  announcementId,
  userId,
}: {
  announcementId: number;
  userId: number;
}): Promise<void> => {
  const response = await fetchAPI(`/announcements/${announcementId}?userId=${userId}`, {
    method: 'DELETE' as const,
  });
  if (!response.isSuccess) {
    throw new Error('Error deleting announcement');
  }
};

interface DeleteContext {
  previousAnnouncements?: Announcement[];
}

const AnnouncementsInbox: React.FC<AnnouncementsInboxProps> = ({ isHidden, onToggle }) => {
  const { t } = useTranslation('global');
  const { user } = useAuth();
  const queryClient = useQueryClient();

  // Use the user id as the participant id (your back end converts it internally).
  const userId = user ? user.id : 0;

  const {
    data: announcements,
    isLoading,
    error,
  } = useQuery(['announcements', userId], () => fetchAnnouncements(userId), { enabled: !!userId });

  // New state for the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Optimistic delete mutation
  const deleteMutation = useMutation(deleteAnnouncementForUser, {
    onMutate: async ({ announcementId, userId }) => {
      await queryClient.cancelQueries(['announcements', userId]);
      const previousAnnouncements = queryClient.getQueryData<Announcement[]>([
        'announcements',
        userId,
      ]);
      queryClient.setQueryData<Announcement[]>(['announcements', userId], (old) =>
        old ? old.filter((a) => a.id !== announcementId) : [],
      );
      return { previousAnnouncements } as DeleteContext;
    },
    onError: (err, variables, context: DeleteContext | undefined) => {
      if (context?.previousAnnouncements) {
        queryClient.setQueryData(['announcements', userId], context.previousAnnouncements);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['announcements', userId]);
    },
  });

  // Helper: get initials from teacher's name.
  const getInitials = (name: string): string => {
    const parts = name.split(' ');
    return parts.map((p) => p.charAt(0).toUpperCase()).join('');
  };

  if (isLoading) return <Loading />;
  if (error) return <div className='inbox-error'>{t('errorFetchingAnnouncements')}</div>;

  // Filter announcements based on the search query (case-insensitive)
  const filteredAnnouncements =
    announcements?.filter((announcement) => {
      const lowerQuery = searchQuery.toLowerCase();
      return (
        announcement.courseName.toLowerCase().includes(lowerQuery) ||
        announcement.teacherName.toLowerCase().includes(lowerQuery) ||
        announcement.message.toLowerCase().includes(lowerQuery)
      );
    }) || [];

  return (
    <div className={`announcements-inbox ${isHidden ? 'hidden' : ''}`}>
      <div className='inbox-header'>
        <h2>{t('inbox')}</h2>
        <button
          className='inbox-toggle-button'
          onClick={onToggle}
          aria-label={t('hideInbox')}
          title={t('hideInbox') || ''}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>
      </div>

      {/* Search bar */}
      <div className='search-bar mb-3'>
        <input
          type='text'
          className='form-control'
          placeholder={t('searchAnnouncements') || 'Search announcements...'}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {filteredAnnouncements.length > 0 ? (
        <div className='announcements-list'>
          {filteredAnnouncements.map((announcement) => (
            <div
              key={announcement.id}
              className={`announcement-card ${announcement.isRead ? 'read' : 'unread'}`}
            >
              <div className='announcement-header'>
                <div className='teacher-avatar'>
                  <span>{getInitials(announcement.teacherName)}</span>
                </div>
                <div className='announcement-info'>
                  <span className='course-name'>{announcement.courseName}</span>
                  <span className='teacher-name'>
                    {t('from')}: {announcement.teacherName}
                  </span>
                  <span className='date-sent'>
                    {new Date(announcement.dateSent).toLocaleString()}
                  </span>
                </div>
              </div>
              <div className='announcement-message'>{announcement.message}</div>
              <div className='announcement-actions'>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={() => deleteMutation.mutate({ announcementId: announcement.id, userId })}
                  disabled={deleteMutation.isLoading}
                >
                  {t('delete')}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='no-announcements'>
          {t('noMatchingAnnouncements') || 'No matching announcements found.'}
        </div>
      )}
    </div>
  );
};

export default AnnouncementsInbox;
