import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
  fetchAPI,
  fetchCourse,
  getParticipantWithCourseId,
  Participant,
  Course, // Import Course type
} from '../../utils/httpRequests';
import { useQuery } from 'react-query';
import { useQueryClient } from 'react-query';
import { useAuth } from '../../utils/stores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import './CoursePage.css';
import Loading from '../../components/Loading';
import GiveRewardsModal from '../../components/modals/GiveRewardsModal';
import StudentInfoModal from '../../components/modals/StudentInfoModal';
import AnnouncementsModal from '../../components/modals/AnnouncementsModal';
import { getAvatarLevel, getNextLevelXP } from '../../utils/avatar';
import { deleteParticipantAction, resetAvatarAction } from '../../utils/courseActions';

interface NewPasswordBody {
  username: string;
  password: string;
}

interface PasswordResetResponse {
  message: string;
}

interface Avatar {
  name: string;
  image: string;
  description: string;
}

const CoursePage = () => {
  const { t } = useTranslation('global');
  const { user, signedIn } = useAuth();
  const { courseId } = useParams();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalIsClosing, setInfoModalIsClosing] = useState(false);
  const [selectedTemporaryPassword, setSelectedTemporaryPassword] = useState('********');
  const [passwordSet, setPasswordSet] = useState(false);
  const [error, setError] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(t('copyPassword'));
  const [initialRender, setInitialRender] = useState(true);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [giveRewardsModalOpen, setGiveRewardsModalOpen] = useState(false);
  const [pointsType, setPointsType] = useState<'xp' | 'gold' | 'life'>('xp');
  const [avatarLevel, setAvatarLevel] = useState(0);
  const [studentAvatar, setStudentAvatar] = useState<Participant | null>(null);
  const [studentXP, setStudentXP] = useState('');
  const [studentGold, setStudentGold] = useState('');
  const [studentHearts, setStudentHearts] = useState('');
  const [xpPercentage, setXpPercentage] = useState('0%');
  const [goldPercentage, setGoldPercentage] = useState('0%');
  const [heartsPercentage, setHeartsPercentage] = useState('0%');
  const [avatarDescription, setAvatarDescription] = useState('');
  const [loadingAvatar, setLoadingAvatar] = useState(true);
  const [avatarName, setUpAvatarName] = useState('');
  const [isEditingStudent, setIsEditingStudent] = useState(false);
  const [editedFirstName, setEditedFirstName] = useState('');
  const [editedLastName, setEditedLastName] = useState('');
  const [editedEmail, setEditedEmail] = useState('');
  const [editedUsername, setEditedUsername] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  // New state for announcement features
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const [announcementRecipients, setAnnouncementRecipients] = useState<number[]>([]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleGearClick = (participant: Participant) => {
    setSelectedParticipant(participant);
    setEditedFirstName(participant.user.firstName);
    setEditedLastName(participant.user.lastName);
    setEditedEmail(participant.user.email);
    setEditedUsername(participant.user.username);
    setInfoModalIsClosing(false);
    setInfoModalOpen(true);
    setIsEditingStudent(false);
  };

  const updateStudentInfo = async () => {
    if (!selectedParticipant) return;
    // Remove email from update payload since it is not allowed to be updated
    const body = {
      firstName: editedFirstName,
      lastName: editedLastName,
      username: editedUsername,
    };
    const response = await fetchAPI(`/users/${selectedParticipant.user.id}`, {
      method: 'PUT',
      body,
    });
    if (response.isSuccess) {
      // Create an updated participant object
      const updatedParticipant: Participant = {
        ...selectedParticipant,
        user: {
          ...selectedParticipant.user,
          firstName: editedFirstName,
          lastName: editedLastName,
          username: editedUsername,
        },
      };
      // Update local state
      setSelectedParticipant(updatedParticipant);
      // Update the react-query cache for the course data
      queryClient.setQueryData<Course | undefined>(['course', courseId], (oldData) => {
        if (oldData && oldData.participants) {
          const updatedParticipants = oldData.participants.map((p: Participant) =>
            p.user.id === updatedParticipant.user.id ? updatedParticipant : p,
          );
          return { ...oldData, participants: updatedParticipants };
        }
        return oldData;
      });
      setIsEditingStudent(false);
    } else {
      alert(t('updateFailed'));
    }
  };

  const handleCancelEdit = () => {
    if (selectedParticipant) {
      setEditedFirstName(selectedParticipant.user.firstName);
      setEditedLastName(selectedParticipant.user.lastName);
      setEditedEmail(selectedParticipant.user.email);
      setEditedUsername(selectedParticipant.user.username);
    }
    setIsEditingStudent(false);
  };

  useEffect(() => {
    if (!initialRender && selectedTemporaryPassword !== '********') {
      const body = {
        username: selectedParticipant?.user.username || '',
        password: selectedTemporaryPassword,
      };
      const fetchData = async (body: NewPasswordBody): Promise<void> => {
        const response = await fetchAPI<PasswordResetResponse>('/auth/set-new-password', {
          method: 'POST',
          body,
        });
        if (response.isSuccess) {
          setError(false);
        } else {
          setError(true);
        }
        setPasswordSet(true);
      };
      fetchData(body);
    }
  }, [selectedTemporaryPassword]);

  const setUpAvatarXP = async (xp: number) => {
    if (!course) return;
    const nextXp = getNextLevelXP(xp, course.xpPerLevel);
    setStudentXP(xp + '/' + nextXp);
    setXpPercentage((xp / nextXp) * 100 + '%');
  };

  const setUpAvatarGold = async (gold: number) => {
    let maxGold = 0;
    if (gold < 10) {
      maxGold = 10;
    } else if (gold < 100) {
      maxGold = 100;
    } else if (gold < 1000) {
      maxGold = 1000;
    } else if (gold < 10000) {
      maxGold = 10000;
    } else if (gold < 25000) {
      maxGold = 25000;
    } else if (gold < 50000) {
      maxGold = 10000;
    } else {
      maxGold = 100000;
      if (gold > maxGold) {
        maxGold = gold;
      }
    }
    setStudentGold(gold + '/' + maxGold);
    setGoldPercentage((gold / maxGold) * 100 + '%');
  };

  const setUpAvatarHearts = async (hearts: number) => {
    let maxHearts = 0;
    if (hearts < 10) {
      maxHearts = 10;
    } else if (hearts < 25) {
      maxHearts = 25;
    } else if (hearts < 50) {
      maxHearts = 50;
    } else if (hearts < 75) {
      maxHearts = 75;
    } else if (hearts < 100) {
      maxHearts = 100;
    } else if (hearts < 150) {
      maxHearts = 150;
    } else {
      maxHearts = 200;
      if (hearts > maxHearts) {
        maxHearts = hearts;
      }
    }
    setStudentHearts(hearts + '/' + maxHearts);
    setHeartsPercentage((hearts / maxHearts) * 100 + '%');
  };

  const {
    data: course,
    isLoading,
    refetch,
  } = useQuery(['course', courseId], () => fetchCourse(courseId));

  useEffect(() => {
    if (course) {
      const fetchParticipant = async () => {
        const response = await getParticipantWithCourseId(courseId);
        if (!response.avatar || response.avatar === '') {
          navigate('/courses/' + courseId + '/avatar/' + response.id);
          return;
        }
        setStudentAvatar(response);
        setAvatarLevel(getAvatarLevel(response.xp, course.xpPerLevel));
        setUpAvatarXP(response.xp);
        setUpAvatarGold(response.gold);
        setUpAvatarHearts(response.life);
        setUpAvatarName(response.avatarName !== null ? response.avatarName : 'NA');
        const avatars = await fetch(`${process.env.PUBLIC_URL}/avatars.json`);
        const data = await avatars.json();
        const matchedAvatar = data.find((avatar: Avatar) => avatar.name === response.avatarName);
        setAvatarDescription(matchedAvatar.description);
      };
      if (signedIn && !user?.teacher && courseId) {
        setLoadingAvatar(true);
        fetchParticipant();
      }
      setLoadingAvatar(false);
    }
  }, [course]);

  useEffect(() => {
    setInitialRender(false);
  }, []);

  const generateTemporaryPassword = async () => {
    setPasswordCopied(t('copyPassword'));
    await new Promise((resolve) => setTimeout(resolve, 500));
    const temporaryPassword = generateRandomPassword();
    setSelectedTemporaryPassword(temporaryPassword);
  };

  const handleModalClose = () => {
    setInfoModalIsClosing(true);
    setTimeout(() => {
      setInfoModalOpen(false);
      setInfoModalIsClosing(false);
    }, 300);
    setSelectedParticipant(null);
    setSelectedTemporaryPassword('********');
    setPasswordSet(false);
  };

  const generateRandomPassword = () => {
    const length = Math.floor(Math.random() * (12 - 8 + 1)) + 8;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]:;<>,.?/~\\-123456789';
    const specialChars = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
    let password = '';
    let hasLowerCase = false;
    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    while (!hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar) {
      password = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      hasLowerCase = /[a-z]/.test(password);
      hasUpperCase = /[A-Z]/.test(password);
      hasNumber = /[0-9]/.test(password);
      hasSpecialChar = new RegExp(`[${specialChars}]`).test(password);
    }
    return password;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedTemporaryPassword);
    navigator.clipboard.readText();
    setPasswordCopied(t('passwordCopied'));
  };

  const deleteParticipant = async (participant: Participant) => {
    await deleteParticipantAction(
      participant,
      t,
      async () => {
        await refetch();
      },
      handleModalClose,
      setDeleteLoading,
    );
  };

  const resetAvatar = async (participant: Participant) => {
    await resetAvatarAction(participant, setResetLoading);
  };

  const participants = course?.participants || [];

  const filteredParticipants = participants
    .filter((participant) => participant.role === 'STUDENT')
    .filter((participant) => {
      const fullName = participant.user.firstName + ' ' + participant.user.lastName;
      const email = participant.user.email || '';
      return (
        fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  const areAllSelected =
    filteredParticipants?.length > 0 &&
    filteredParticipants?.every((participant) => selectedStudents.includes(participant.id));

  const toggleStudentSelection = (participantId: number) => {
    if (selectedStudents.includes(participantId)) {
      setSelectedStudents(selectedStudents.filter((id) => id !== participantId));
    } else {
      setSelectedStudents([...selectedStudents, participantId]);
    }
  };

  const toggleSelectAll = () => {
    if (areAllSelected) {
      setSelectedStudents([]);
    } else {
      const allStudentIds = filteredParticipants?.map((participant) => participant.id);
      setSelectedStudents(allStudentIds ? allStudentIds : []);
    }
  };

  const openGiveRewardsModal = async (type: 'xp' | 'gold' | 'life'): Promise<void> => {
    setPointsType(type);
    setGiveRewardsModalOpen(true);
  };

  const closeGiveRewardsModal = async (): Promise<void> => {
    setGiveRewardsModalOpen(false);
    await refetch();
  };

  // New function to open the announcement modal with specified recipients
  const openAnnouncementModal = (recipients: number[]) => {
    setAnnouncementRecipients(recipients);
    setAnnouncementModalOpen(true);
  };

  // New function to send an announcement message
  const sendAnnouncement = async (message: string, recipients: number[]) => {
    const payload = {
      courseId: courseId,
      recipients: recipients,
      message: message,
    };
    const response = await fetchAPI('/announcements', {
      method: 'POST',
      body: payload,
    });
    if (!response.isSuccess) {
      throw new Error('Failed to send announcement');
    }
  };

  const getColorFromName = (name: string): string => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, 75%, 50%)`;
  };

  return (
    <>
      <div className='mw-100 d-flex flex-column h-100 p-3'>
        {isLoading || loadingAvatar || !(course && course.participants) ? (
          <Loading />
        ) : (
          <div className='d-flex flex-column h-100 mw-100' data-testid='coursePage'>
            {signedIn && user?.teacher ? (
              <>
                <h1>
                  {t('courseIntroText')} {course.name}
                </h1>
                <h3>
                  {t('teacher')}:{' '}
                  {course.participants.find((p) => p.role === 'TEACHER')?.user.firstName}{' '}
                  {course.participants.find((p) => p.role === 'TEACHER')?.user.lastName}
                </h3>
                <div>
                  <h1 className='mt-3'>{t('students')}</h1>
                  {/* New button for sending announcement to all students */}
                  <button
                    className='btn btn-primary m-1'
                    onClick={() =>
                      openAnnouncementModal(
                        filteredParticipants.map((participant) => participant.id),
                      )
                    }
                  >
                    {t('sendAnnouncementToAll')}
                  </button>
                  {course.participants.some((p) => p.role === 'STUDENT') ? (
                    <div className='d-flex flex-column' style={{ height: '65vh' }}>
                      <div>
                        <button
                          className='btn btn-primary m-1'
                          data-testid='giveXpButton'
                          disabled={selectedStudents.length === 0}
                          onClick={() => openGiveRewardsModal('xp')}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/xp.png`}
                            alt='XP Icon'
                            className='icon-image'
                          />
                        </button>
                        <button
                          className='btn btn-primary m-1'
                          data-testid='goldButton'
                          disabled={selectedStudents.length === 0}
                          onClick={() => openGiveRewardsModal('gold')}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/gold-coins.png`}
                            alt='Or Icon'
                            className='icon-image'
                          />
                        </button>
                        <button
                          className='btn btn-primary m-1'
                          data-testid='lifeButton'
                          disabled={selectedStudents.length === 0}
                          onClick={() => openGiveRewardsModal('life')}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/heart.png`}
                            alt='Life Icon'
                            className='icon-image'
                          />
                        </button>
                      </div>
                      <div className='my-3'>
                        <input
                          type='search'
                          className='form-control w-25 bg-light border-dark text-dark shadow-sm'
                          placeholder={t('search')}
                          data-testid='searchInput'
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setSelectedStudents([]);
                          }}
                        />
                      </div>
                      <div className='flex-grow-1' style={{ overflowY: 'auto' }}>
                        <table className='table'>
                          <thead className='sticky'>
                            <tr>
                              <th className='w-5'>
                                <input
                                  className='form-check-input me-1'
                                  type='checkbox'
                                  data-testid='selectAllCheckbox'
                                  checked={areAllSelected}
                                  onChange={toggleSelectAll}
                                />
                              </th>
                              <th>{t('name')}</th>
                              <th>{t('email')}</th>
                              <th>{t('XP')}</th>
                              <th>{t('gold')}</th>
                              <th>{t('life')}</th>
                              <th>{t('actions')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredParticipants
                              .sort((a, b) =>
                                a.user.firstName
                                  .toLowerCase()
                                  .localeCompare(b.user.firstName.toLowerCase()),
                              )
                              .map((participant) => (
                                <tr key={participant.id}>
                                  <td className='align-middle'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      data-testid={`studentCheckbox-${participant.id}`}
                                      checked={selectedStudents.includes(participant.id)}
                                      onChange={() => toggleStudentSelection(participant.id)}
                                    />
                                  </td>
                                  <td className='align-middle'>
                                    <div className='d-flex align-items-center'>
                                      <div
                                        className='initial-circle me-2'
                                        style={{
                                          backgroundColor: getColorFromName(
                                            participant.user.firstName + participant.user.lastName,
                                          ),
                                        }}
                                      >
                                        {participant.user.firstName.charAt(0)}
                                        {participant.user.lastName.charAt(0)}
                                      </div>
                                      <div>
                                        {participant.user.firstName} {participant.user.lastName}
                                      </div>
                                    </div>
                                  </td>
                                  <td className='align-middle'>{participant.user.email}</td>
                                  <td className='align-middle'>{participant.xp}</td>
                                  <td className='align-middle'>{participant.gold}</td>
                                  <td className='align-middle'>{participant.life}</td>
                                  <td className='align-middle'>
                                    <button
                                      type='button'
                                      className='btn btn-primary align-middle'
                                      data-testid={`gearButton-${participant.id}`}
                                      onClick={() => handleGearClick(participant)}
                                    >
                                      <FontAwesomeIcon icon={faGear} />
                                    </button>
                                    {/* New message button for individual announcements */}
                                    <button
                                      type='button'
                                      className='btn btn-primary m-1'
                                      onClick={() => openAnnouncementModal([participant.id])}
                                    >
                                      <FontAwesomeIcon icon={faEnvelope} />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <h6>{t('noStudentsMessage')}</h6>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className='d-flex flex-lg-row flex-grow-1 h-100 mw-100'>
                  <div className='d-flex flex-lg-row flex-grow-1 h-100 mw-50 align-items-center justify-content-center'>
                    <div className='w-100 h-75 d-flex flex-column align-items-center justify-content-center'>
                      <h2 className='mb-5'>{avatarName}</h2>
                      <img
                        src={`${process.env.PUBLIC_URL}/${studentAvatar?.avatar}`}
                        alt='Avatar'
                        style={{
                          objectFit: 'contain',
                          width: 'auto',
                          height: '100%',
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className='w-50 h-100 d-flex align-items-center flex-column'
                    style={{
                      backgroundColor: 'rgba(0, 17, 255, 0.75)',
                      borderRadius: '22px',
                      paddingTop: '20px',
                    }}
                  >
                    <h2>
                      <p style={{ color: 'white' }}>
                        {t('courseIntroText')} {course.name}
                      </p>
                    </h2>
                    <h3>
                      <p style={{ color: 'white' }}>
                        {t('teacher')}:{' '}
                        {course.participants.find((p) => p.role === 'TEACHER')?.user.firstName}{' '}
                        {course.participants.find((p) => p.role === 'TEACHER')?.user.lastName}
                      </p>
                    </h3>
                    <div id='carouselExample' className='carousel slide d-flex flex-grow-1 w-100'>
                      <div className='carousel-inner h-100'>
                        <div className='carousel-item active h-100'>
                          <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                            <div className='container d-flex justify-content-center align-items-center'>
                              <div
                                className='progress-circle'
                                style={{
                                  background: `conic-gradient(#007bff 0%,rgb(0, 123, 255) ${avatarLevel}%, #d9d9d9 ${avatarLevel}%)`,
                                }}
                              >
                                <div className='progress-value text-center'>
                                  <div className='container'>
                                    <div className='row'>
                                      <div className='col'>
                                        <h4 className='mb-0'>{t('level')}</h4>
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <div className='col'>
                                        <h4>{avatarLevel}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='container'>
                              <div className='d-flex justify-content-between align-items-center progress-headline'>
                                <h3 className='progress-title mb-0 mt-auto'>
                                  <p style={{ color: 'white', margin: 0 }}>
                                    {t('XP')} - {studentXP}
                                  </p>
                                </h3>
                                <img
                                  src={`${process.env.PUBLIC_URL}/xp.png`}
                                  alt='XP Icon'
                                  className='icon'
                                />
                              </div>
                              <div className='progress'>
                                <div
                                  className='progress-bar progress-bar-danger progress-bar-striped active'
                                  style={{ width: xpPercentage, backgroundColor: '#28a745' }}
                                ></div>
                              </div>
                              <div className='d-flex justify-content-between align-items-center progress-headline'>
                                <h3 className='progress-title mb-0 mt-auto'>
                                  <p style={{ color: 'white', margin: 0 }}>
                                    {t('gold')} - {studentGold}
                                  </p>
                                </h3>
                                <img
                                  src={`${process.env.PUBLIC_URL}/gold-coins.png`}
                                  alt='Gold Icon'
                                  className='icon'
                                />
                              </div>
                              <div className='progress'>
                                <div
                                  className='progress-bar progress-bar-info progress-bar-striped active'
                                  style={{ width: goldPercentage, backgroundColor: '#FFD700' }}
                                ></div>
                              </div>
                              <div className='d-flex justify-content-between align-items-center progress-headline'>
                                <h3 className='progress-title mb-0 mt-auto'>
                                  <p style={{ color: 'white', margin: 0 }}>
                                    {t('life')} - {studentHearts}
                                  </p>
                                </h3>
                                <img
                                  src={`${process.env.PUBLIC_URL}/heart.png`}
                                  alt='Heart Icon'
                                  className='icon'
                                />
                              </div>
                              <div className='progress'>
                                <div
                                  className='progress-bar progress-bar-info progress-bar-striped active'
                                  style={{ width: heartsPercentage, backgroundColor: '#ff4d4d' }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='carousel-item h-100'>
                          <div className='d-flex justify-content-center align-items-center h-100'>
                            <p className='bubble-text'>{avatarDescription}</p>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center w-100 position-absolute top-50 translate-middle-y'>
                        <button
                          className='carousel-control-prev'
                          type='button'
                          data-bs-target='#carouselExample'
                          data-bs-slide='prev'
                          style={{ width: '50px', height: '50px', marginTop: -120 }}
                        >
                          <span
                            className='carousel-control-prev-icon'
                            aria-hidden='true'
                            style={{ width: '100%', height: '100%' }}
                          ></span>
                          <span className='visually-hidden'>Previous</span>
                        </button>
                        <button
                          className='carousel-control-next'
                          type='button'
                          data-bs-target='#carouselExample'
                          data-bs-slide='next'
                          style={{ width: '50px', height: '50px', marginTop: -120 }}
                        >
                          <span
                            className='carousel-control-next-icon'
                            aria-hidden='true'
                            style={{ width: '100%', height: '100%' }}
                          ></span>
                          <span className='visually-hidden'>Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <StudentInfoModal
          isOpen={infoModalOpen}
          isClosing={infoModalIsClosing}
          selectedParticipant={selectedParticipant}
          onClose={handleModalClose}
          generateTemporaryPassword={generateTemporaryPassword}
          copyToClipboard={copyToClipboard}
          passwordSet={passwordSet}
          error={error}
          selectedTemporaryPassword={selectedTemporaryPassword}
          passwordCopied={passwordCopied}
          updateStudentInfo={updateStudentInfo}
          handleCancelEdit={handleCancelEdit}
          deleteParticipant={deleteParticipant}
          resetAvatar={resetAvatar}
          deleteLoading={deleteLoading}
          resetLoading={resetLoading}
          isEditing={isEditingStudent}
          setIsEditing={setIsEditingStudent}
          editedFirstName={editedFirstName}
          editedLastName={editedLastName}
          editedEmail={editedEmail}
          editedUsername={editedUsername}
          setEditedFirstName={setEditedFirstName}
          setEditedLastName={setEditedLastName}
          setEditedEmail={setEditedEmail}
          setEditedUsername={setEditedUsername}
        />
        <GiveRewardsModal
          isOpen={giveRewardsModalOpen}
          onClose={closeGiveRewardsModal}
          pointsType={pointsType}
          participantList={selectedStudents}
        />
        {/* New AnnouncementsModal component */}
        <AnnouncementsModal
          isOpen={announcementModalOpen}
          recipients={announcementRecipients}
          onClose={() => setAnnouncementModalOpen(false)}
          onSend={sendAnnouncement}
        />
      </div>
    </>
  );
};

export default CoursePage;
